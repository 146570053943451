import Pubnub from 'pubnub'

import { NOTIFICATION_MESSAGE_TYPE } from './api'
import { ArtistBrand, Event, MuteType, User } from './api/baseTypes'
import { Merge, Overwrite } from './helperTypes'

export type MatchProp = {
  eventSlug: string
  ticketId: string
}

export type AvrMatchProp = {
  slug: string
}

export type LocationProp = {
  redirectedFromPage: string
  isPreview?: boolean
  parentMomentRoute?: string
}

export type OnReactionPickParam = {
  /** the message channel */
  channel: string
  /** message time token */
  messageTimetoken: string
  /** reaction value */
  value: string
}

export type OnRemoveReactionParam = {
  /** the message channel */
  channel: string
  /** message time token */
  messageTimetoken: string
  /** action time token */
  actionTimetoken: string
  /** reaction value */
  value?: string
}

export type VirtualMsgData = {
  user?: Partial<User | null>
  msgs: PbMsg[]
  dispatch?: any
  setSize: (index: number, size: number) => void
  onDeleteClick?: (msg: PbMsg<Merge<MsgData<'NEW' | 'REPLY'>>>) => void
  onGeneralMuteClick?: (msg: PbMsg<Merge<MsgData<'NEW' | 'REPLY'>>>) => void
  onArtistMuteClick?: (msg: PbMsg<Merge<MsgData<'NEW' | 'REPLY'>>>) => void
  onReportClick?: (msg: PbMsg<Merge<MsgData<'NEW' | 'REPLY'>>>) => void
  onReplyClick?: (e: MsgData<'REPLY'>) => void
  onTipClick?: (data: MsgData<'TIP'>) => void
  scrollToItem?: (timetoken: string) => void
  showItemMessageDateAndTime?: (timeToken: string) => void
  /** on bought merch callback */
  onBoughtMerchClick?: (
    /** selected merch bundle */
    bundle: MsgBundlePurchase,
    /** element reference */
    elem?: HTMLDivElement
  ) => void
  onDmClick?: (uuid: string) => void
  /** the timeToken to be deleted */
  deletingTimeToken?: string
  /** the timeToken to be muted */
  mutingTimeToken?: string
  /** the current message time token on reply mode  */
  replyTimeToken?: string
  reportingTimeToken?: string
  timeToken?: string
  /** holds timetoken for the msg we are displaying date and time */
  dateTimeToken?: string
  onNameClick?: (userId?: string) => void
  /** enable additional context menu popup when user hovers the senders image  */
  enableInfoPopupOnImgHover?: boolean
  /**
   * message list variant.
   * - legacy - is what we use in main app, prior to the massive design changes.
   * - chat - messenger like chat
   *  */
  bubbleVariant?: 'legacy' | 'chat'
  /** on profile image click */
  onChatProfileImageClick?: (userId?: string) => void
  /** on reaction picked for a msg */
  onReactionPick?: (param: OnReactionPickParam) => void
  /** on reaction remove from a msg */
  onRemoveReaction?: (param: OnRemoveReactionParam) => void
  /** if reaction feature is enabled */
  reactionEnabled?: boolean
  /** if reaction picker is enabled */
  reactionPickerEnabled?: boolean
  /** on bubble long press event */
  onBubbleLongPress?: (msg: PbMsg) => void
}

export type Level = {
  name: string
  width: number
  height: number
  level: number
}

export type BufferStats = {
  buffered?: number[][]
  corruptedVideoFrames?: number
  creationTime?: number
  droppedVideoFrames?: number
  played?: number[][]
  seekable?: number[][]
  totalFrameDelay?: number
  totalVideoFrames?: number
}

export type LatencyStats = {
  latencyTime?: number
  localTime?: number
  streamTime?: number
}

export type LevelStats = {
  audioCodec?: string
  bitrate?: number
  levelIndex?: number
  name?: string
  videoCodec?: string
}

export type VideoStats = {
  buffer?: BufferStats
  latency?: LatencyStats
  level?: LevelStats
}

export type MsgBundlePurchase = {
  id: string
  name: string
  variantName: string
  variantId: string
}

export enum SocketEmitterTypes {
  CREATOR_ONLINE = 'CREATOR_ONLINE',
  CREATOR_OFFLINE = 'CREATOR_OFFLINE',

  WORLD_MUTED = 'WORLD_MUTED',
  USER_WORLD_BANNED = 'USER_WORLD_BANNED',
  USER_WORLD_UNBANNED = 'USER_WORLD_UNBANNED',
  USER_GLOBAL_BANNED = 'USER_GLOBAL_BANNED',
  USER_REFETCH = 'USER_REFETCH',

  BLOCKED_BY_USER = 'BLOCKED_BY_USER',
  UNBLOCKED_BY_USER = 'BLOCKED_BY_USER',

  PARTY_UPDATES = 'PARTY_UPDATES',
  JOIN_PARTY = 'JOIN_PARTY',
  LEAVE_PARTY = 'LEAVE_PARTY',
  RECIEVED_FOLLOWER = 'RECIEVED_FOLLOWER',
  PARTY_INVITE = 'PARTY_INVITE',
  CLIENT_REFRESH = 'CLIENT_REFRESH',
}

export type MsgType =
  | 'NEW'
  | 'REPLY'
  | 'PINNED'
  | 'DELETE'
  | 'MUTED'
  | 'UNMUTED'
  | 'REPORTED'
  | 'EV_UPDATE'
  | 'ARTIST_UPDATE'
  | 'HARD_REFRESH'
  | 'TIP'
  | 'BOUGHT_BUNDLE'
  | 'POLL'
  | 'DELETE_POLL'
  | 'VOD_UPDATE'

  // Additional Emitter Messages
  | SocketEmitterTypes.CREATOR_ONLINE
  | SocketEmitterTypes.CREATOR_OFFLINE
  | SocketEmitterTypes.WORLD_MUTED
  | SocketEmitterTypes.USER_WORLD_BANNED
  | SocketEmitterTypes.USER_WORLD_UNBANNED
  | SocketEmitterTypes.USER_GLOBAL_BANNED
  | SocketEmitterTypes.USER_REFETCH
  | SocketEmitterTypes.BLOCKED_BY_USER
  | SocketEmitterTypes.UNBLOCKED_BY_USER
  | SocketEmitterTypes.PARTY_UPDATES
  | SocketEmitterTypes.JOIN_PARTY
  | SocketEmitterTypes.LEAVE_PARTY
  | SocketEmitterTypes.RECIEVED_FOLLOWER
  | SocketEmitterTypes.PARTY_INVITE
  | SocketEmitterTypes.CLIENT_REFRESH

  // PERSISTENT + LIVE NOTIFICATIONS
  | NOTIFICATION_MESSAGE_TYPE.NOTIFICATION_RECIEVED_FOLLOWER
  | NOTIFICATION_MESSAGE_TYPE.NOTIFICATION_BROADCAST_LIVE
  | NOTIFICATION_MESSAGE_TYPE.NOTIFICATION_BROADCAST_NOT_LIVE
  | NOTIFICATION_MESSAGE_TYPE.NOTIFICATION_SCHEDULED_APPEARANCE
  | NOTIFICATION_MESSAGE_TYPE.NOTIFICATION_SCHEDULED_APPEARANCE_NOTIFIED
  | NOTIFICATION_MESSAGE_TYPE.NOTIFICATION_APPEARANCE_REMINDER
  | NOTIFICATION_MESSAGE_TYPE.CREATOR_POST
  | NOTIFICATION_MESSAGE_TYPE.CREATOR_POST_PIN
  | NOTIFICATION_MESSAGE_TYPE.CREATOR_POST_COMMENT
  | NOTIFICATION_MESSAGE_TYPE.BOARD_POST_COMMENT
  | NOTIFICATION_MESSAGE_TYPE.CREATOR_POST_UPVOTE
  | NOTIFICATION_MESSAGE_TYPE.BOARD_POST_UPVOTE
  | NOTIFICATION_MESSAGE_TYPE.CREATOR_CHATS_ANNOUNCEMENT
  | NOTIFICATION_MESSAGE_TYPE.DIRECT_MESSAGE
  | NOTIFICATION_MESSAGE_TYPE.CREATOR_DIRECT_MESSAGE
  | NOTIFICATION_MESSAGE_TYPE.NOTIFICATION_CUSTOM
  | NOTIFICATION_MESSAGE_TYPE.AGGREGATED_BOARD_POST_UPVOTE
  | NOTIFICATION_MESSAGE_TYPE.AGGREGATED_BOARD_POST_COMMENT
  | NOTIFICATION_MESSAGE_TYPE.AGGREGATED_BOARD_COMMENT_UPVOTE

type EVIdentifier = { evId?: string }

type UserIdentifier = { userId: string }

export type MsgDeleteData = EVIdentifier & { timetoken: string }

export type MsgMuteData = EVIdentifier & UserIdentifier & { muteType: MuteType }

export type MsgUnmuteData = EVIdentifier &
  UserIdentifier & { muteType: MuteType }

export type MsgEvUpdateData = EVIdentifier & Partial<Event>

export type MsgArtistUpdateData = { artistId: string } & Partial<ArtistBrand>

export type MsgHardRefreshData = EVIdentifier

export type MsgTipData = {
  tipValue: number
  tipVerb?: string
}

export type MsgBundlePurchaseData = {
  bundles?: MsgBundlePurchase[]
}

export type MsgPollData = EVIdentifier & {
  poll: any // ?? need to type poll
  action: string
  id: string
  question?: string
}

export type MsgDeletePoll = EVIdentifier & {
  userName: string
}

export type PartyUpdatesData = {
  type?:
    | 'MEMBER_REMOVE'
    | 'MEMBER_MUTED'
    | 'MEMBER_UNMUTED'
    | 'OFFLINE_MEMBER'
    | 'ONLINE_MEMBER'
    | 'OWNER_TRANSFER'
    | 'USER_DECLINE'
    | 'MEET_UP'
    | 'JOIN_USER_REQUEST'
    | 'JOIN_USER_RESPONSE'
    | 'JOIN_USER_PROCEED'
  createdAt?: string
  worldDomain?: string
  worldId?: string
  gameServerKey?: string
  serverIp?: string
  userId?: string
  uid?: string
  isPartyOwner?: boolean
  joinRequest?: { userId: string; from: string } // broadcast to fetch world + server info of target user id
  joinResponse?: {
    from: string
    userId?: string
    domain?: string
    server?: string
    worldId?: string
    serverIp?: string
    position?: { x: number; y: number; z: number }
  } // userId refers to receiver id
  position?: { x: number; y: number; z: number }
}

export type MsgReplyData = {
  userId: string
  userName: string
  timetoken: string
  channel: string
  text?: string
  userImage?: string
}

export type MsgMuteWorldData = {
  muted: boolean
}

export type MsgData<T extends MsgType> = T extends 'NEW'
  ? MsgReplyData
  : T extends 'PINNED'
  ? EVIdentifier
  : T extends 'DELETE'
  ? MsgDeleteData
  : T extends 'MUTED'
  ? MsgMuteData
  : T extends 'UNMUTED'
  ? MsgUnmuteData
  : T extends 'EV_UPDATE'
  ? MsgEvUpdateData
  : T extends 'ARTIST_UPDATE'
  ? MsgArtistUpdateData
  : T extends 'HARD_REFRESH'
  ? MsgHardRefreshData
  : T extends 'TIP'
  ? MsgTipData
  : T extends 'BOUGHT_BUNDLE'
  ? MsgBundlePurchaseData
  : T extends 'POLL'
  ? MsgPollData
  : T extends 'DELETE_POLL'
  ? MsgDeletePoll
  : T extends 'REPLY'
  ? MsgReplyData
  : T extends 'PARTY_UPDATES'
  ? PartyUpdatesData
  : T extends 'WORLD_MUTED'
  ? MsgMuteWorldData
  : never

export type AllMsgDataTypes = Merge<MsgData<MsgType>>

type IMsgPayload = MsgType | AllMsgDataTypes

export type MsgPayload<D extends IMsgPayload = AllMsgDataTypes> = {
  /** message type */
  type: MsgType
  /** sender user name */
  userName: string
  /** sender user id */
  userId: string
  /** no needed back compat, channel is optional */
  channel?: string
  /** msg */
  text?: string
  /** sender image */
  userImage?: string
  /** msg data */
  data?: D extends MsgType ? MsgData<D> : D
  /** if msg is hidden */
  isHidden?: boolean
  /** if msg is highlighted */
  isHighlighted?: boolean
  /** if sender is host */
  isHost?: boolean
  /** is sender logged in */
  isLoggedIn?: boolean
  /** if msg is reported */
  isReported?: boolean
  /** if user has valid subscription */
  isSubscriber?: boolean
  /** sender artist id */
  artistId?: string
  /** if sender has patreon enabled */
  hasPatreon?: boolean
  /** if user.verified_creator is true */
  verifiedCreator?: boolean
  /** if message is admin muted is true */
  isAdminMuted?: boolean
}

export type PubnubMessageEvent = Overwrite<
  Pubnub.MessageEvent,
  { message: MsgPayload; channels?: string[] }
>

export type PbMsgReaction = {
  actionTimetoken: string
  uuid: string
}

export type PbMsgReactions = { [key: string]: PbMsgReaction[] }

export type PbMsg<D extends IMsgPayload = AllMsgDataTypes> = {
  channel: string
  message: MsgPayload<D>
  timetoken: string
  uuid: string
  actions?: { reaction: PbMsgReactions }
}

export type LastMsg = {
  /** user id of source */
  userId?: string
  /** the message string */
  msg: string
  /** the message time token */
  timeToken: number
}

/** last message map */
export type LastMsgsMap = { [channel: string]: LastMsg }
