import 'moment/locale/de.js'
import 'moment/locale/es.js'
import 'moment/locale/fr.js'
import 'moment/locale/he.js'
import 'moment/locale/is.js'
import 'moment/locale/ja.js'
import 'moment/locale/nl.js'
import 'moment/locale/zh-cn.js'

import { AuthProvider } from '@momenthouse/auth'
import GeoProvider from '@momenthouse/geo'
import { I18nProvider } from '@momenthouse/I18n'
import { useTheme } from '@momenthouse/ui'
import Navigation from 'components/Common/Navigation'
import CookieBanner from 'components/CookieBanner'
import { PAYPAL_CLIENT_ID, STRIPE_PUBLIC_KEY } from 'config'
import {
  FB_PIXEL_HYPERCULTURE,
  FB_PIXEL_MOMENTHOUSE,
  GTM_MOMENTHOUSE,
} from 'constants/analytics'
import { seoConfig } from 'constants/seo'
import { GlobalProvider } from 'context/global'
import useFocus from 'hooks/useFocus'
import useIdentify from 'hooks/useIdentify'
import usePageView from 'hooks/usePageView'
import usePrivateRoute from 'hooks/usePrivateRoute'
import usePublicOnlyRoute from 'hooks/usePublicOnlyRoute'
import moment from 'moment'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { DefaultSeo, NextSeoProps } from 'next-seo'
import { ReactNode } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useHotkeys } from 'react-hotkeys-hook'

const ABSwitcher = dynamic(() => import('components/ArtistBrandSwitcher'), {
  ssr: false,
})
const GlobalModal = dynamic(() => import('components/GlobalModal/modal'), {
  ssr: false,
})

type Props = {
  children: ReactNode
  /* The document.title value of the page */
  title?: string
  /* Render the default navigation component */
  showNav?: boolean
  /* Render the custom navigation component for artist + checkout */
  showCreatorNav?: boolean
  /* Only accessible to signed-in users */
  isPrivate?: boolean
  /* Only accessible to signed-out users */
  isPublicOnly?: boolean
  /* Disables the default SEO <meta /> tags */
  customSEO?: NextSeoProps
}

export default function GlobalLayout({
  children,
  showNav,
  title,
  isPrivate,
  isPublicOnly,
  customSEO,
}: Props) {
  /* Internationalization - query value sent from _middleware.ts */
  const { query } = useRouter()
  const locale = query.locale || 'en-US'
  // Sets locale for date/time functionality
  moment.locale(locale as string)

  /* Toggle document 'light' | 'dark' className */
  const { applyTheme } = useTheme()
  useHotkeys('cmd+/,ctrl+/', (e) => {
    e.preventDefault()
    const isDarkMode = document.documentElement.className.includes('dark')
    applyTheme(isDarkMode ? 'light' : 'dark')
  })

  const seo = customSEO
    ? customSEO
    : {
        ...seoConfig,
        ...(title && { title: `${title} | Moment` }),
      }

  useFocus()
  useIdentify()
  usePageView(seo.title)

  return (
    <>
      <DefaultSeo {...seo} />
      <GeoProvider>
        <I18nProvider locale={locale}>
          <AuthProvider>
            <GlobalProvider>
              {/* @ts-ignore Type error: Type '{ children: Element; }' has no properties in common with type 'IntrinsicAttributes'. */}
              <div id="root">
                {/* @ts-ignore React 18 */}
                <DndProvider backend={HTML5Backend}>
                  {showNav && <Navigation />}
                  <InnerGlobalLayout
                    isPrivate={isPrivate}
                    isPublicOnly={isPublicOnly}
                  >
                    {children}
                  </InnerGlobalLayout>
                  <GlobalModal />
                  <ABSwitcher />
                  <CookieBanner />
                </DndProvider>
              </div>
            </GlobalProvider>
          </AuthProvider>
        </I18nProvider>
      </GeoProvider>
      <Script
        id="onetrust-cookie-consent"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script={
          process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
            ? '566338d3-ba55-4a21-b4c9-c461c828e960'
            : '566338d3-ba55-4a21-b4c9-c461c828e960-test'
        }
        strategy="beforeInteractive"
        onLoad={() => {
          function OptanonWrapper() {}
          // @ts-ignore
          window?.OneTrust?.Close?.()
        }}
        onError={(e) => {
          console.error('Script failed to load', e)
        }}
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_MOMENTHOUSE});
            fbq('init', ${FB_PIXEL_HYPERCULTURE});
          `,
        }}
      />
      <Script
        id="google-places-init"
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}&libraries=places`}
        strategy="afterInteractive"
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_MOMENTHOUSE}');
          `,
        }}
      />
      {/** The new GA4 Property */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=G-YBLG34L1XC`}
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-YBLG34L1XC');
          `,
        }}
      />
    </>
  )
}

const InnerGlobalLayout = ({ children, isPrivate, isPublicOnly }) => {
  usePublicOnlyRoute(isPublicOnly)
  usePrivateRoute(isPrivate)

  return <>{children}</>
}
